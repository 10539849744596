const useConfig = () => {
  const BSC_CHAIN_ID = process.env.REACT_APP_BSC_CHAINID;
  const POLYGON_CHAIN_ID = process.env.REACT_APP_POLYGON_CHAINID;
  const RPC_URL_BSC = process.env.REACT_APP_RPC_URL_BSC;
  const BLOCK_URL_BSC = process.env.REACT_APP_BLOCK_URL_BSC;
  const BSC_CHAIN_NAME = process.env.REACT_APP_CHAIN_NAME_BSC;
  const RPC_URL_POLYGON = process.env.REACT_APP_RPC_URL_MATIC;
  const BLOCK_URL_POLYGON = process.env.REACT_APP_BLOCK_URL_MATIC;
  const POLYGON_CHAIN_NAME = process.env.REACT_APP_CHAIN_NAME_MATIC;
  const PAYMENT_LINK = process.env.REACT_APP_PAYMENT_LINK;
  const BSC_PAYMENT_CONTRACT_ADDRESS =
    process.env.REACT_APP_CRYPTO_PAYMENT_CONTRACT_ADDRESS_SMART_CHAIN;
  const POLYGON_PAYMENT_CONTRACT_ADDRESS =
    process.env.REACT_APP_CRYPTO_PAYMENT_CONTRACT_ADDRESS_MATIC;
  const BSC_TOKEN_CONTRACT_ADDRESS =
    process.env.REACT_APP_OBOT_TOKEN_CONTRACT_ADDRESS_SMART_CHAIN;
  const POLYGON_TOKEN_CONTRACT_ADDRESS =
    process.env.REACT_APP_OBOT_TOKEN_CONTRACT_ADDRESS_MATIC;
  return {
    BSC_CHAIN_ID,
    POLYGON_CHAIN_ID,
    RPC_URL_BSC,
    BLOCK_URL_BSC,
    BSC_CHAIN_NAME,
    RPC_URL_POLYGON,PAYMENT_LINK,
    POLYGON_CHAIN_NAME,
    BLOCK_URL_POLYGON,
    BSC_PAYMENT_CONTRACT_ADDRESS,
    POLYGON_PAYMENT_CONTRACT_ADDRESS,
    BSC_TOKEN_CONTRACT_ADDRESS,
    POLYGON_TOKEN_CONTRACT_ADDRESS,
  };
};

export default useConfig;
