import React from "react";
import { Button, Modal } from "react-bootstrap";

const WrongNetworkModal = ({
  onChangeNetworkClickMatic,
  onChangeNetworkClickBinance,
  show,
  disabledButton,
}) => {
  return (
    <Modal
      size="md"
      centered
      show={show}
      style={{ display: show ? "flex" : "none" }}
    >
      <Modal.Body className="text-center">
        <h5 className="my-2">
          <strong>You're currently on wrong network</strong>
        </h5>
        <p className="mt-3">
          Obortech supports{" "}
          <strong>Mumbai Testnet and Smart Chain - Testnet</strong> network
          only, please switch to{" "}
          <strong>Mumbai Testnet or Smart Chain - Testnet</strong> manually or
          click Switch network button
        </p>
        <div className="d-flex justify-content-around">
          <Button
            className="modal-button"
            onClick={onChangeNetworkClickMatic}
            disabled={disabledButton}
          >
            Change to Polygon
          </Button>
          <Button
            className="modal-button"
            onClick={onChangeNetworkClickBinance}
            disabled={disabledButton}
          >
            Change to Binance
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WrongNetworkModal;
