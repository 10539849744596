import * as actionLabels from "../../actionLabels";

const initialState = {
  isLoading: false,
  errorMsg: "",
  successMsg: "",
  priceData: "",
  searchParams: {},
  cancelTransaction: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionLabels.TRANSACTION_START: {
      return {
        ...state,
        isLoading: true,
        errorMsg: "",
        successMsg: "",
      };
    }
    case actionLabels.TRANSACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        successMsg: action.payload,
      };
    }
    case actionLabels.TRANSACTION_FAIL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload,
      };
    }
    case actionLabels.GET_PRICE_START: {
      return {
        ...state,
        isLoading: true,
        errorMsg: "",
        successMsg: "",
      };
    }
    case actionLabels.GET_PRICE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        priceData: action.payload,
      };
    }
    case actionLabels.GET_PRICE_FAIL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload,
      };
    }
    case actionLabels.SET_SEARCH_PARAMS: {
      return {
        ...state,
        searchParams: action.payload,
      };
    }
    case actionLabels.CANCEL_TRANSACTION_START: {
      return {
        ...state,
        isLoading: true,
        errorMsg: "",
        cancelTransaction: "",
      };
    }
    case actionLabels.CANCEL_TRANSACTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        cancelTransaction: action.payload,
      };
    }
    case actionLabels.CANCEL_TRANSACTION_FAIL: {
      return {
        ...state,
        isLoading: false,
        errorMsg: action.payload,
      };
    }
    default:
      return state;
  }
};
