import * as actionLabels from '../../actionLabels'

export const transactionStart = (payload) => {
	return {
		type: actionLabels.TRANSACTION_START,
		payload,
	}
}

export const transaction = (payload) => {
	return {
		type: actionLabels.TRANSACTION_SAGA,
		payload,
	}
}

export const transactionSuccess = (payload) => {
	return {
		type: actionLabels.TRANSACTION_SUCCESS,
		payload,
	}
}

export const transactionFail = (payload) => {
	return {
		type: actionLabels.TRANSACTION_FAIL,
		payload,
	}
}

export const getPriceStart = (payload) => {
	return {
		type: actionLabels.GET_PRICE_START,
		payload,
	}
}

export const getPrice = (payload) => {
	return {
		type: actionLabels.GET_PRICE_SAGA,
		payload,
	}
}

export const getPriceSuccess = (payload) => {
	return {
		type: actionLabels.GET_PRICE_SUCCESS,
		payload,
	}
}

export const getPriceFail = (payload) => {
	return {
		type: actionLabels.GET_PRICE_FAIL,
		payload,
	}
}

export const setSearchParams = (payload) => {
	return {
		type: actionLabels.SET_SEARCH_PARAMS,
		payload,
	}
}

export const cancelTransactionStart = (payload) => {
	return {
		type: actionLabels.CANCEL_TRANSACTION_START,
		payload,
	}
}
export const SuccessTransactionStart = (payload) => {
	return {
		type: actionLabels.SUCCESS_TRANSACTION_START,
		payload,
	}
}

export const cancelTransaction = (payload) => {
	return {
		type: actionLabels.CANCEL_TRANSACTION_SAGA,
		payload,
	}
}
export const SuccessTransaction = (payload) => {
	return {
		type: actionLabels.SUCCESS_TRANSACTION_SAGA,
		payload,
	}
}

export const cancelTransactionSuccess = (payload) => {
	return {
		type: actionLabels.CANCEL_TRANSACTION_SUCCESS,
		payload,
	}
}
export const SuccessTransactionSuccess = (payload) => {
	return {
		type: actionLabels.SUCCESS_TRANSACTION_SUCCESS,
		payload,
	}
}

export const cancelTransactionFail = (payload) => {
	return {
		type: actionLabels.CANCEL_TRANSACTION_FAIL,
		payload,
	}
}

export const SuccessTransactionFail = (payload) => {
	return {
		type: actionLabels.SUCCESS_TRANSACTION_FAIL,
		payload,
	}
}
