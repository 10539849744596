const TRANSACTION_START = 'TRANSACTION_START'
const TRANSACTION_SAGA = 'TRANSACTION_SAGA'
const TRANSACTION_SUCCESS = 'TRANSACTION_SUCCESS'
const TRANSACTION_FAIL = 'TRANSACTION_FAIL'

const GET_PRICE_START = 'GET_PRICE_START'
const GET_PRICE_SAGA = 'GET_PRICE_SAGA'
const GET_PRICE_SUCCESS = 'GET_PRICE_SUCCESS'
const GET_PRICE_FAIL = 'GET_PRICE_FAIL'

const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS'

const CANCEL_TRANSACTION_START = 'CANCEL_TRANSACTION_START'
const CANCEL_TRANSACTION_SAGA = 'CANCEL_TRANSACTION_SAGA'
const SUCCESS_TRANSACTION_START = 'SUCCESS_TRANSACTION_START'
const CANCEL_TRANSACTION_SUCCESS = 'CANCEL_TRANSACTION_SUCCESS'
const SUCCESS_TRANSACTION_SUCCESS = 'SUCCESS_TRANSACTION_SUCCESS'
const SUCCESS_TRANSACTION_SAGA = 'SUCCESS_TRANSACTION_SAGA'
const CANCEL_TRANSACTION_FAIL = 'CANCEL_TRANSACTION_FAIL'
const SUCCESS_TRANSACTION_FAIL = 'SUCCESS_TRANSACTION_FAIL'

export {
	TRANSACTION_START,
	TRANSACTION_SAGA,
	TRANSACTION_SUCCESS,
	TRANSACTION_FAIL,
	GET_PRICE_START,
	GET_PRICE_SAGA,
	SUCCESS_TRANSACTION_START,
	GET_PRICE_SUCCESS,
	SUCCESS_TRANSACTION_FAIL,
	SUCCESS_TRANSACTION_SUCCESS,
	GET_PRICE_FAIL,
	SET_SEARCH_PARAMS,
	SUCCESS_TRANSACTION_SAGA,
	CANCEL_TRANSACTION_START,
	CANCEL_TRANSACTION_SAGA,
	CANCEL_TRANSACTION_SUCCESS,
	CANCEL_TRANSACTION_FAIL,
}
