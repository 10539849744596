import React from "react";
import { LOGO } from "../../assets/images";

const Layout = ({ children }) => (
  <>
    <div className="wrapper">
      <div className="my-2">
        <img className="logo-img" src={LOGO} alt="" />
      </div>
      <div className="mt-2 mb-4 login-head-text">
        <span>Discount By Token</span>
      </div>
      <div className="p-3">{children}</div>
    </div>
  </>
);

export default Layout;
